.position_rel {
  position: relative;
}

.background_white {
  background: #fff !important;
}

.background_color {
  background: #f1f5f9 !important;
}

.shape-7 {
  transform: rotate(180deg);
  position: absolute;
  right: 0;
  bottom: 20px;
}

.navbar-area {
  border-bottom: 1px solid #f5f5f5;
}

/* SLIDER SECTION */
.hero-section-wrapper-1 {
  overflow: hidden;
}

.hero-section-wrapper-1 .hero-style-1 .hero-content-wrapper {
  padding: 230px 0 !important;
}

.hero-section-wrapper-1 .hero-image img {
  /* width: 113% !important;
    max-width: 113% !important; */

}

.hero-section-wrapper-1 h1 {
  font-weight: 600 !important;
}

/* PARTNER SECTION */
/* .trusted_partner {
    background: #fff !important;
  } */

/* OUR EXPERTISE */
.our_expertise .single-feature p {
  min-height: 50px;
}

.grid_images {
  column-count: 3;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  grid-column-gap: 30px;
  column-gap: 30px;
}

/* EVENT SECTION */
.event_section {
  padding: 100px 0 70px;
}

.event_section .single-gallery {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px;
  margin-bottom: 30px;
}

.event_section .single-gallery .overlay {
  background: rgba(255, 255, 255, 0.9);
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.event_section .single-gallery .overlay .overlay-content .action a {
  background: #206bc4;
  color: #fff;
}

.event_section .single-gallery .overlay .overlay-content .info h5,
.event_section .single-gallery .overlay .overlay-content .info p {
  color: #000;
}

/* OUR HR PLOICY */
.our_policy {
  background: #E5E5E5;
}

.our_policy .single-gallery .info {
  transition: all 0.3s ease-out 0s;
  background: #ffffff;
  margin-left: 30px;
  margin-bottom: 15px;
  padding: 30px 20px;
  border-radius: 5px 0 5px 5px;
  margin-top: -70px;
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.our_policy .single-gallery .info h5::after {
  content: "";
  background: #206bc4;
  height: 2px;
  width: 60px;
  display: block;
  margin-top: 12px;
}

.our_policy .single-gallery .info p {
  margin-bottom: 0px;
}

/* CAREERS SECTION */
.careers {}

/* Testimonials section */
.testimonials_section {}

.testimonials_section .owl-dots {
  display: none;
}

.testimonials_section .owl-nav {
  top: 50%;
  position: absolute;
  margin-top: -40px;
  width: 100%;
}

.testimonials_section .owl-nav .owl-prev {
  left: -25px;
  position: absolute;
}

.testimonials_section .owl-nav .owl-next {
  right: 0;
  position: absolute;
}

.testimonials_section .owl-nav button span {
  font-size: 43px;
}

.testimonials_section .single-testimonial .content-wrapper .content p {
  font-size: 16px;
}


/* END */

.about-style-4 {
  background: #f3f3f3;
  padding: 100px 0 50px;
  position: relative;
  z-index: 1;
}

.specializing .single-feature {
  padding: 30px 35px;
  border-radius: 10px;
  margin-bottom: 40px;
  margin-top: 30px;
  box-shadow: 0 5px 25px rgba(218, 211, 211, .3);
}

.specializing .single-feature .icon {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #206bc4;
  color: #fff;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -60px;
}

.specializing {
  background: #fff;
  padding: 100px 0;
}

.about-style-4 .about-content-wrapper ul {
  padding-bottom: 25px;
}

.about-style-4 .about-content-wrapper ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.about-style-4 .about-content-wrapper ul li i {
  width: 16px;
  height: 16px;
  margin-right: 15px;
  margin-top: 4px;
  font-weight: 700;
}

.career .card {
  border: none;
  border-radius: 10px
}

.career .c-details span {
  font-weight: 300;
  font-size: 13px
}

.career .icon {
  width: 50px;
  height: 50px;
  background-color: #eee;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 39px
}

.career .badge span {
  background-color: #206bc4;
  width: 60px;
  height: 25px;
  padding-bottom: 3px;
  border-radius: 5px;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center
}

.career .progress {
  height: 10px;
  border-radius: 10px
}

.career .progress div {
  background-color: #206bc4;
}

.career .text1 {
  font-size: 14px;
  font-weight: 600
}

.career .text2 {
  color: #a5aec0
}

/* ---------------------------- RESPONISVE START ---------------------------- */

@media only screen and (min-width: 1361px) {
  .hero-section-wrapper-1 .hero-image img {
    /* width: calc(100vw - 1140px/1.2);  */
    width: 120% !important;
    max-width: 120% !important;
  }
}

@media only screen and (max-width: 1360px) {
  .hero-section-wrapper-1 .hero-style-1 .hero-content-wrapper {
    padding: 160px 0 !important;
  }

  .hero-section-wrapper-1 .hero-image img {
    width: 120% !important;
    max-width: 120% !important;
    /* width: calc(100vw - 1140px/1.7); */
  }
}

@media only screen and (max-width: 991px) {
  .trusted_partner .col-xl-7.col-lg-6 {
    order: 2;
  }

  .header.header-4 .navbar-toggler {
    padding: 4px;
  }

  .header.header-4 .navbar-nav .nav-item a {
    width: 100%;
  }

  .header.header-4 .navbar-nav .nav-item a::after {
    display: none;
  }

  .hero-section-wrapper-1 .hero-style-1 .hero-content-wrapper {
    padding: 30px 0 !important;
  }

  .event_section .grid_images {
    column-count: 2;
  }

  .career.careers .fadeInLeft {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  body section {
    padding: 40px 0 40px 0px !important;
  }

  .hero-section h1,
  .hero-section .h1 {
    font-size: 36px !important;
  }

  body h3,
  body .h3 {
    font-size: 29px !important;
  }

  html body .our_policy {
    margin-bottom: 0;
  }

  html body section.careers {
    margin-top: 0 !important;
  }

  .shape-7 {
    display: none;
  }

  .careers>.row {
    padding: 15px;
  }

  .our_expertise h3 {
    margin-top: 20px;
  }

  .testimonial-style-2 .single-testimonial .content-wrapper {
    margin-bottom: 0px;
  }

  .testimonials_section .owl-nav {
    display: none;
  }

  .testimonials_section .owl-dots {
    display: block;
    text-align: center;
  }

  .testimonials_section .owl-dots .owl-dot {
    margin: 0px 4px;
  }

  .testimonials_section .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: #dcdcdc;
    border-radius: 30px;
  }

  .testimonials_section .owl-dots .owl-dot.active span {
    background: #206bc4;
  }

  .footer-style-1 .widget-wrapper .footer-widget {
    margin-bottom: 20px;
  }

  .careers {
    overflow: hidden;
  }

  .hero-section-wrapper-1 .hero-style-1 .hero-image {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 480px) {
  .event_section .grid_images {
    column-count: 1;
  }

  .our_policy .single-gallery .info {
    margin-left: 0;
  }

  .hero-section-wrapper-1 {
    background: #ebf4ff;
  }
}

.our-hr-policies ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}

.our-hr-policies ul li div img {
  width: 100%;
  height: 100%;
}

.our-hr-policies ul a {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 129.5%;
  color: #000000;
  text-align: center;
  display: block;
  text-decoration: none;
  margin-top: 20px;
}

.our-hr-policies {
  max-width: 1400px;
  margin: 0 auto;
  padding:  0 0 30px 0;
}

.our-hr-policies a span {
  color: #206BC4;
}

.our-hr-policies ul li {
  background: #fff;
  padding: 8px 8px 20px 8px;
  border-radius: 20px;
}


.displayGrid{
  display: grid
}