.single-policy h4 {
  margin-bottom: 10px;
}

.single-policy p {
  margin-bottom: 10px;
}

.single-policy li {
  line-height: 30px;
}