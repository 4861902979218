.rdt_TableRow {
    padding: 5px 0px;
}

.text-muted {
    color: rgba(97, 104, 118, 1) !important;
}

.cursor {
    cursor: pointer;
}