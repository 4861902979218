.img_box {
    position: relative;
    overflow: hidden;
    padding-top: 100%;
}
.pagination li.page-item a {
   display: flex;
   justify-content: center;
   align-items: center;
}
/* .img_box {
   overflow: hidden;
   display: inline-block;
} */

.img_box>img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: absolute;
   top: 0;
   left: 0;
}

.img_box img.disabled {
   filter: grayscale(1);
}

.hover {
   position: absolute;
   top: 0%;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.8);
   display: flex;
   gap: 10px;
   justify-content: center;
   align-items: center;
   padding: 15px;
   transition: all 0.3s;
   transform: scale(0.5);
   opacity: 0;
}

.hover>div {
   flex: 1;
   display: inline-flex;
   justify-content: center;
}

.img_box:hover .hover {
   transform: scale(1);
   opacity: 1;
}

.img_box .thumbnail {
   max-width: 30px;
   flex: 0 0 30px;
   height: 30px;
   overflow: hidden;
   width: 100%;
   display: inline-block;
}

.img_box .thumbnail img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.img_box .cursor {
   cursor: pointer;
   overflow: hidden;
   display: inline-block;
}

.react-switch .react-switch-bg {
   margin: 0 auto !important;
}