body {
    color: var(--bs-body-color) !important;
}

:host,
:root {
    font-size: 16px;
    height: 100%;
    --bs-body-color: #585978;
    --bs-table-color: #585978;
    --bs-primary: #206bc4;
    --bs-primary-fg: var(--bs-light);
    --bs-primary-darken: #1d60b0;
    --bs-primary-rgb: 32, 107, 196 !important;
    --bs-secondary: #616876;
    --bs-secondary-fg: var(--bs-light);
    --bs-secondary-darken: #575e6a;
    --bs-secondary-rgb: 97, 104, 118;
    --bs-success: #2fb344;
    --bs-success-fg: var(--bs-light);
    --bs-success-darken: #2aa13d;
    --bs-success-rgb: 47, 179, 68;
    --bs-info: #4299e1;
    --bs-info-fg: var(--bs-light);
    --bs-info-darken: #3b8acb;
    --bs-info-rgb: 66, 153, 225;
    --bs-warning: #f76707;
    --bs-warning-fg: var(--bs-light);
    --bs-warning-darken: #de5d06;
    --bs-warning-rgb: 247, 103, 7;
    --bs-danger: #d63939;
    --bs-danger-fg: var(--bs-light);
    --bs-danger-darken: #c13333;
    --bs-danger-rgb: 214, 57, 57;
    --bs-light: #f8fafc;
    --bs-light-fg: var(--bs-dark);
    --bs-light-darken: #dfe1e3;
    --bs-light-rgb: 248, 250, 252;
    --bs-dark: #1d273b;
    --bs-dark-fg: var(--bs-light);
    --bs-dark-darken: #1a2335;
    --bs-dark-rgb: 29, 39, 59;
    --bs-muted: #616876;
    --bs-muted-fg: var(--bs-light);
    --bs-muted-darken: #575e6a;
    --bs-muted-rgb: 97, 104, 118;
    --bs-blue: #206bc4;
    --bs-blue-fg: var(--bs-light);
    --bs-blue-darken: #1d60b0;
    --bs-blue-rgb: 32, 107, 196;
    --bs-azure: #4299e1;
    --bs-azure-fg: var(--bs-light);
    --bs-azure-darken: #3b8acb;
    --bs-azure-rgb: 66, 153, 225;
    --bs-indigo: #4263eb;
    --bs-indigo-fg: var(--bs-light);
    --bs-indigo-darken: #3b59d4;
    --bs-indigo-rgb: 66, 99, 235;
    --bs-purple: #ae3ec9;
    --bs-purple-fg: var(--bs-light);
    --bs-purple-darken: #9d38b5;
    --bs-purple-rgb: 174, 62, 201;
    --bs-pink: #d6336c;
    --bs-pink-fg: var(--bs-light);
    --bs-pink-darken: #c12e61;
    --bs-pink-rgb: 214, 51, 108;
    --bs-red: #d63939;
    --bs-red-fg: var(--bs-light);
    --bs-red-darken: #c13333;
    --bs-red-rgb: 214, 57, 57;
    --bs-orange: #f76707;
    --bs-orange-fg: var(--bs-light);
    --bs-orange-darken: #de5d06;
    --bs-orange-rgb: 247, 103, 7;
    --bs-yellow: #f59f00;
    --bs-yellow-fg: var(--bs-light);
    --bs-yellow-darken: #dd8f00;
    --bs-yellow-rgb: 245, 159, 0 !important;
    --bs-lime: #74b816;
    --bs-lime-fg: var(--bs-light);
    --bs-lime-darken: #68a614;
    --bs-lime-rgb: 116, 184, 22;
    --bs-green: #2fb344;
    --bs-green-fg: var(--bs-light);
    --bs-green-darken: #2aa13d;
    --bs-green-rgb: 47, 179, 68;
    --bs-teal: #0ca678;
    --bs-teal-fg: var(--bs-light);
    --bs-teal-darken: #0b956c;
    --bs-teal-rgb: 12, 166, 120;
    --bs-cyan: #17a2b8;
    --bs-cyan-fg: var(--bs-light);
    --bs-cyan-darken: #1592a6;
    --bs-cyan-rgb: 23, 162, 184;
    --bs-facebook: #1877F2;
    --bs-facebook-fg: var(--bs-light);
    --bs-facebook-darken: #166bda;
    --bs-facebook-rgb: 24, 119, 242;
    --bs-twitter: #1da1f2;
    --bs-twitter-fg: var(--bs-light);
    --bs-twitter-darken: #1a91da;
    --bs-twitter-rgb: 29, 161, 242;
    --bs-linkedin: #0a66c2;
    --bs-linkedin-fg: var(--bs-light);
    --bs-linkedin-darken: #095caf;
    --bs-linkedin-rgb: 10, 102, 194;
    --bs-google: #dc4e41;
    --bs-google-fg: var(--bs-light);
    --bs-google-darken: #c6463b;
    --bs-google-rgb: 220, 78, 65;
    --bs-youtube: #ff0000;
    --bs-youtube-fg: var(--bs-light);
    --bs-youtube-darken: #e60000;
    --bs-youtube-rgb: 255, 0, 0;
    --bs-vimeo: #1ab7ea;
    --bs-vimeo-fg: var(--bs-light);
    --bs-vimeo-darken: #17a5d3;
    --bs-vimeo-rgb: 26, 183, 234;
    --bs-dribbble: #ea4c89;
    --bs-dribbble-fg: var(--bs-light);
    --bs-dribbble-darken: #d3447b;
    --bs-dribbble-rgb: 234, 76, 137;
    --bs-github: #181717;
    --bs-github-fg: var(--bs-light);
    --bs-github-darken: #161515;
    --bs-github-rgb: 24, 23, 23;
    --bs-instagram: #e4405f;
    --bs-instagram-fg: var(--bs-light);
    --bs-instagram-darken: #cd3a56;
    --bs-instagram-rgb: 228, 64, 95;
    --bs-pinterest: #bd081c;
    --bs-pinterest-fg: var(--bs-light);
    --bs-pinterest-darken: #aa0719;
    --bs-pinterest-rgb: 189, 8, 28;
    --bs-vk: #6383a8;
    --bs-vk-fg: var(--bs-light);
    --bs-vk-darken: #597697;
    --bs-vk-rgb: 99, 131, 168;
    --bs-rss: #ffa500;
    --bs-rss-fg: var(--bs-light);
    --bs-rss-darken: #e69500;
    --bs-rss-rgb: 255, 165, 0;
    --bs-flickr: #0063dc;
    --bs-flickr-fg: var(--bs-light);
    --bs-flickr-darken: #0059c6;
    --bs-flickr-rgb: 0, 99, 220;
    --bs-bitbucket: #0052cc;
    --bs-bitbucket-fg: var(--bs-light);
    --bs-bitbucket-darken: #004ab8;
    --bs-bitbucket-rgb: 0, 82, 204;
    --bs-tabler: #206bc4;
    --bs-tabler-fg: var(--bs-light);
    --bs-tabler-darken: #1d60b0;
    --bs-tabler-rgb: 32, 107, 196;
    --bs-gray-50: #f8fafc;
    --bs-gray-50-fg: var(--bs-dark);
    --bs-gray-50-darken: #dfe1e3;
    --bs-gray-50-rgb: 248, 250, 252;
    --bs-gray-100: #f1f5f9;
    --bs-gray-100-fg: var(--bs-dark);
    --bs-gray-100-darken: #d9dde0;
    --bs-gray-100-rgb: 241, 245, 249;
    --bs-gray-200: #e2e8f0;
    --bs-gray-200-fg: var(--bs-dark);
    --bs-gray-200-darken: #cbd1d8;
    --bs-gray-200-rgb: 226, 232, 240;
    --bs-gray-300: #c8d3e1;
    --bs-gray-300-fg: var(--bs-dark);
    --bs-gray-300-darken: #b4becb;
    --bs-gray-300-rgb: 200, 211, 225;
    --bs-gray-400: #9ba9be;
    --bs-gray-400-fg: var(--bs-light);
    --bs-gray-400-darken: #8c98ab;
    --bs-gray-400-rgb: 155, 169, 190;
    --bs-gray-500: #6c7a91;
    --bs-gray-500-fg: var(--bs-light);
    --bs-gray-500-darken: #616e83;
    --bs-gray-500-rgb: 108, 122, 145;
    --bs-gray-600: #49566c;
    --bs-gray-600-fg: var(--bs-light);
    --bs-gray-600-darken: #424d61;
    --bs-gray-600-rgb: 73, 86, 108;
    --bs-gray-700: #313c52;
    --bs-gray-700-fg: var(--bs-light);
    --bs-gray-700-darken: #2c364a;
    --bs-gray-700-rgb: 49, 60, 82;
    --bs-gray-800: #1d273b;
    --bs-gray-800-fg: var(--bs-light);
    --bs-gray-800-darken: #1a2335;
    --bs-gray-800-rgb: 29, 39, 59;
    --bs-gray-900: #0f172a;
    --bs-gray-900-fg: var(--bs-light);
    --bs-gray-900-darken: #0e1526;
    --bs-gray-900-rgb: 15, 23, 42;
    --bs-bg-surface: var(--bs-white);
    --bs-bg-surface-secondary: var(--bs-light);
    --bs-bg-surface-dark: var(--bs-dark);
    --bs-bg-forms: var(--bs-bg-surface);
    --bs-border-color: #e6e7e9 !important;
    --bs-border-color-light: #f2f3f4 !important;
    --bs-border-color-active: #b3b7bd !important;
    --bs-icon-color: var(--bs-gray-500);
    --bs-active-bg: rgba(var(--bs-primary-rgb), 0.04);
    --bs-disabled-bg: var(--bs-gray-100);
    --bs-disabled-color: var(--bs-gray-300);
    --bs-code-color: var(--bs-gray-600);
    --bs-code-bg: var(--bs-gray-100);
    --bs-dark-mode-border-color: #243049;
    --bs-dark-mode-border-color-light: #243049;
    --bs-dark-mode-border-color-active: #314264;
    --bs-font-weight-light: 300;
    --bs-font-weight-normal: 400;
    --bs-font-weight-medium: 500;
    --bs-font-weight-bold: 600;
    --bs-font-weight-headings: var(--bs-font-weight-medium);
    --bs-font-size-h1: 1.5rem;
    --bs-font-size-h2: 1.25rem;
    --bs-font-size-h3: 1rem;
    --bs-font-size-h4: 0.875rem;
    --bs-font-size-h5: 0.75rem;
    --bs-font-size-h6: 0.625rem;
    --bs-line-height-h1: 2rem;
    --bs-line-height-h2: 1.75rem;
    --bs-line-height-h3: 1.5rem;
    --bs-line-height-h4: 1.25rem;
    --bs-line-height-h5: 1rem;
    --bs-line-height-h6: 1rem;
    --bs-shadow: rgba(var(--bs-body-color-rgb), 0.04) 0 2px 4px 0;
    --bs-shadow-transparent: 0 0 0 0 transparent;
    --bs-shadow-button: 0 1px 0 rgba(var(--bs-body-color-rgb), 0.04);
    --bs-shadow-button-inset: inset 0 -1px 0 rgba(var(--bs-body-color-rgb), 0.2);
    --bs-shadow-card: 0 0 4px rgba(var(--bs-body-color-rgb), 0.04);
    --bs-shadow-card-hover: rgba(var(--bs-body-color-rgb), 0.16) 0 2px 16px 0;
    --bs-border-width: 1px;
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #206bc4 !important;

}

.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1.5rem;
    --bs-card-title-spacer-y: 1.25rem;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-border-color);
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-box-shadow: var(--bs-shadow-card);
    --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-card-cap-padding-y: 1rem;
    --bs-card-cap-padding-x: 1.5rem;
    --bs-card-cap-bg: var(--bs-bg-surface-secondary);
    --bs-card-cap-color: inherit;
    --bs-card-color: inherit;
    --bs-card-bg: var(--bs-bg-surface);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
}

.row-deck>.col .card,
.row-deck>[class*=col-] .card {
    flex: 1 1 auto;
}

.card {
    transition: transform .3s ease-out, opacity .3s ease-out, box-shadow .3s ease-out;
}

.card-header {
    color: inherit;
    display: flex;
    align-items: center;
    background: 0 0;
}

.card-header {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    margin-bottom: 0;
    color: var(--bs-card-cap-color);
    background-color: #fff !important;
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}



.card-table {
    margin-bottom: 0 !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

/* table thead th,
.table thead th {
    color: var(--bs-muted);
    background: var(--bs-gray-50);
    font-size: .625rem;
    font-weight: var(--bs-font-weight-bold);
    text-transform: uppercase;
    letter-spacing: .04em;
    line-height: 1rem;
    color: var(--bs-muted);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    white-space: nowrap;
} */

.card-table tr td:first-child,
.card-table tr th:first-child {
    padding-left: 1.5rem;
    border-left: 0;
}

.card-table tr td:last-child,
.card-table tr th:last-child {
    padding-right: 1.5rem;
    border-right: 0;
}

.table-vcenter>:not(caption)>*>* {
    vertical-align: middle;
}

img {
    max-width: 100%;
}

.progress {
    --bs-progress-height: 0.5rem !important;
    --bs-progress-font-size: 0.65625rem;
    --bs-progress-bg: var(--bs-border-color);
    --bs-progress-border-radius: var(--bs-border-radius);
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #ffffff;
    --bs-progress-bar-bg: var(--bs-primary);
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-lt {
    --bs-bg-opacity: .1;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-green-rgb, var(--bs-text-opacity))) !important;
    background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-lt {
    --bs-bg-opacity: .1;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-red-rgb, var(--bs-text-opacity))) !important;
    background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.progress-bar {
    height: 100%;
}


.form-control {
    padding-right: 20px !important;
    padding-left: 14px !important;
    border-radius: 10px !important;
    font-weight: 300 !important;
    width: 100% !important;
    height: 25px !important;
}

.select-form-control {
    padding-right: 20px !important;
    padding-left: 14px !important;
    border-radius: 10px !important;
    font-weight: 300 !important;
    width: auto !important;
    height: 25px !important;
}

textarea.form-control {
    padding-right: 20px !important;
    padding-left: 14px !important;
    border-radius: 10px !important;
    font-weight: 300 !important;
    width: 100% !important;
    height: auto !important;
}

.form-label {
    display: block;
    margin-bottom: 10px;
    color: #000;
}



.badge:empty {
    display: inline-block !important;
    width: .5rem;
    height: .5rem;
    min-width: 0;
    min-height: auto;
    padding: 0;
    border-radius: 100rem;
    vertical-align: baseline;
}

.avatar {
    --bs-avatar-size: 2.5rem;
    --bs-avatar-bg: var(--bs-gray-100);
    position: relative;
    width: var(--bs-avatar-size);
    height: var(--bs-avatar-size);
    font-size: calc(var(--bs-avatar-size)/ 2.8571428572);
    font-weight: var(--bs-font-weight-medium);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--bs-muted);
    text-align: center;
    text-transform: uppercase;
    vertical-align: bottom;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: var(--bs-avatar-bg) no-repeat center/cover;
    border-radius: var(--bs-border-radius);
}

.avatar .badge {
    /*  position: absolute; */
    right: 0;
    bottom: 0;
    border-radius: 100rem;
    box-shadow: 0 0 0 2px var(--bs-bg-surface);
}

.page-header .page-title {
    margin: 0;
    font-size: 1.25rem !important;
    line-height: 1.75rem;
    font-weight: 600;
    color: inherit;
    display: flex;
    align-items: center;
}

.subheader {
    font-size: .625rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .04em;
    line-height: 1rem;
    color: #616876;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.btn {
    font-size: 14px;
}

.btn-primary {
    background-color: #206bc4 !important;
    color: #f8fafc !important;
    display: inline-flex;
    border-color: #206bc4 !important;
    padding: 5px 10px !important;
}

.btn.btn-primary:hover {
    background-color: #fff !important;
    color: #206bc4 !important;
}

.btn-outline-primary {
    color: #206bc4 !important;
    padding: 5px 10px !important;
}

.btn-outline-primary:hover {
    background-color: #206bc4 !important;
    color: #f8fafc !important;
}

.btn-list {
    --bs-list-gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: var(--bs-list-gap);
}

.list-group-transparent {
    border-radius: 0;
    --bs-list-group-border-radius: 0;
    margin: 0 -17px;
}

.list-group-transparent .list-group-item.active {
    font-weight: 600;
    color: inherit;
    background: rgba(32, 107, 196, 0.04);
}

.list-group-transparent .list-group-item {
    background: 0 0;
    border: 0;
}

/* 
.h2,
h2 {
    font-size: 1.25rem !important;
    line-height: 1.25rem !important;
} */


#textTestimonials {
    height: 100px !important;
}

#btnSearch svg {
    font-size: 14px;
}

#btnSearch {
    background-color: #206bc4;
    padding: 5px 10px;
    font-size: 14px !important;
    color: #fff;
}

#btnSearchReset {
    font-size: 14px;
    padding: 5px 10px;
    font-size: 14px !important;
}

#btnSearchReset svg {
    font-size: 14px;
}

.page-item .page-link {
    font-size: 16px;
    padding: 2px;
    border-radius: 50%;
    margin: 5px 5px;
    width: 30px;
    height: 30px;
    color: #000;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #206bc4;
    border-color: #206bc4;
    font-size: 16px;
    padding: 2px;
    border-radius: 50% !important;
    margin: 5px 5px;
    width: 30px;
    height: 30px;
}

.page-item:last-child .page-link {
    margin-right: 50px;
    font-size: 16px;
    padding: 2px;
    border-radius: 50% !important;
    margin: 5px 5px;
    width: 30px;
    height: 30px;
    color: #000;
}

.page-item:first-child .page-link {
    font-size: 16px;
    padding: 2px;
    border-radius: 50% !important;
    margin: 5px 5px;
    width: 30px;
    height: 30px;
    color: #000;
}

.nav-pills {
    --bs-nav-pills-border-radius: 0.375rem;
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #206bc4;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #206bc4 !important;
}

.nav-pills .nav-link {
    color: #585978;
}

.nav-pills .nav-link.active {
    display: flex;
    position: relative;
    border: 0;
    border-radius: 0;
    margin: 0 -2px 0 -4px;
}

.acc-set-l-panel {
    border-right: 1px solid #e1e1e1;
    height: 100%;
    /* margin-top: 21px !important; */
}

.limit-pagination {
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    min-height: 56px;
    background-color: #FFFFFF;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: rgba(0, 0, 0, .12);
}


.table-responsive button[type=submit] {
    background-color: #206bc4 !important;
    color: #f8fafc !important;
    display: inline-flex;
    border-color: #206bc4 !important;
    padding: 5px 10px !important;
    margin: 5px;
    display: grid;
}

.table-responsive button[type=button] {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
    display: inline-flex;
    padding: 5px 10px !important;
    margin: 5px;
    display: grid;
}

.table-responsive button[type=submit]:hover {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #206bc4 !important;
}

.table-responsive button[type=button]:hover {
    color: #000;
    background-color: #fff;
    border-color: #d9534f;
}

.policyDeleteBtn {
    display: block;
    margin-top: 10px;
}

.leave-status-alert {
    padding-bottom: 20px;
}

.leave-status-alert-approve {
    padding: 9px;
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.leave-status-alert-reject {
    padding: 9px;
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
}

.leave-status-alert-cancel {
    padding: 9px;
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
}

.leave-status-alert-pending {
    padding: 9px;
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

.cancel-leave {
    margin-left: 10px;
}

.leave-details p {
    font-size: 12px;
}

.f-13 {
    font-size: 13px;
}

.f-14 {
    font-size: 14px;
}

.f-16 {
    font-size: 16px;
}

.fw-500 {
    font-weight: 500 !important;
    ;
}

.fw-400 {
    font-weight: 400 !important;
    ;
}

.ticket-pagination li {
    display: inline-block;
    padding: 5px;
    font-size: 15px;
    color: #000;
}