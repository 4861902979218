.dashboard-dept img {
    width: 48px;
    height: 48px;

}

.react-tabs__tab-list {
    padding: 10px !important;
    color: #000;
}

.react-tabs__tab--selected {
    background: #f6f8fc !important;
    border-color: #aaa;
    color: #0d6efd !important;
    /* border-radius: 5px 5px 0 0; */
    border: none !important;
    border-bottom: 1px solid #0d6efd;
}

.react-tabs__tab {
    border-right: 1px solid #b6b6b6 !important;
    border-radius: 0 !important;

}