.personal-info {
  padding-top: 15px;
}

.personal-info li {
  padding: 5px 0;
  word-wrap: break-word;
  color: #000;
  line-height: 25px;
}

.personal-info li svg {
  padding-right: 3px;

}

.personal-info li a {
  color: black;
}

.employee-img img {
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.employee-img span {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  display: block;
}

.socialView li {
  display: inline-flex;
  padding: 0 5px 5px 0;
  color: #000;
  font-size: 15px;
  width: 50% !important;
  line-height: 25px;
}

.socialView {
  width: 100%;
}