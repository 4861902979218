.comments-container {
    max-height: 300px; 
    overflow-y: auto; 
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .comment {
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .other-comment {
    background-color: #f0f0f0;
    align-self: flex-start;
  }
  
  .own-comment {
    background-color: #007bff;
    color: white;
    align-self: flex-end;
  }

  .comment-header {
    font-weight: bold;
  }
  